import React, { Component } from 'react';
import './App.css';
import logo from './img/logo.png'
import globle from './img/globle.png'
import product from './img/product.png'
import apple from './img/apple.png'
import android from './img/android.png'
import know from './img/know.png'
import commute from './img/commute.png'
import Button from '@material-ui/core/Button'
import action from './img/action.png'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Container, Row, Col, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'linear-gradient(45deg, #8ec44f 100%, #FF8E53 90%)'
      }
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

// componentDidMount() {
//   window.addEventListener('scroll',()=>{
//     var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
//     if(scrollTop<0) {
//     } else if (scrollTop>0) {
//       // this.scrollToAnchor('featurePage');
//     }
//   });
// }

  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if(anchorElement) {anchorElement.scrollIntoView({behavior:'smooth'})};
    }
  }

  render() {
    return (
      <div class="layout">
        <Navbar light expand="md">
                  <NavbarBrand href="/"><img style={{width:"40%"}}src={logo} /></NavbarBrand>
                  <NavbarToggler onClick={this.toggle} />
                  <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="navItemP" navbar>
                    <NavItem>
                      <button className="navLink-override" onClick={()=>this.scrollToAnchor('/')}>首页</button>
                    </NavItem>
                      <NavItem>
                        <button className="navLink-override" onClick={()=>this.scrollToAnchor('featurePage')}>应用介绍</button>
                      </NavItem>
                      <NavItem>
                        <button className="navLink-override" onClick={()=>this.scrollToAnchor('downloadPage')}>下载</button>
                      </NavItem>
                    </Nav>
          </Collapse>
        </Navbar>


        <img class="running" src={action} />
        <div id="landingPage" class="first-content">
          <p>SPORTS | LIFE | HEALTH</p>
          <h2 class="emphasize-font">智能体育 用<span style={{color:"#fa7564"}}>心</span>生活 呼吸健康</h2>
          <p class="front-topic">步入智能时代，GPE将是您运动生活的健康伴侣</p>
          <div class="btn_more">
            <button className="button-strap" onClick={()=>this.scrollToAnchor('featurePage')}>更多</button>
          </div>
        </div>

        <div id="featurePage">
          <Container>
            <Row>
              <Col sm="4" className="img-animation">
                <img src={globle}/>
                <p style={{color:'#aaaaaa'}}><i> Customize your sports life </i></p>
                <h3> 私人定制 </h3>
                根据自己的运动轨迹建立专属于个人的体育数据记录
              </Col>
              <Col sm="4" className="img-animation">
                <img src={commute} />
                <p style={{color:'#aaaaaa'}}><i> Build your relationships </i></p>
                <h3> 社区联系 </h3>
                加入自己感兴趣的社区，参加比赛或与相同爱好者一起运动
              </Col>
              <Col sm="4" className="img-animation">
                <img src={know} />
                <p style={{color:'#aaaaaa'}}><i>Get the latest sports news in time </i></p>
                <h3> 最新资讯 </h3>
                及时获取最新的体育新闻，更进最火体育赛事
              </Col>
            </Row>
          </Container>
          <div class="btn_down">
            <button className="button-strap" onClick={()=>this.scrollToAnchor('downloadPage')}>进入下载</button>
          </div>
        </div>

        <div id="downloadPage">
        <Container>
          <Row>
            <Col>
              <img src={product} style={{width:"120%"}}/>
            </Col>
            <Col></Col>
            <Col>
              <h2 class="download">下载通道</h2>
              <p class="download-font">DOWNLOAD PAGE</p>
              <img src={apple} style={{width:"20%"}}/>
              <hr/>
              <p>IOS</p>
              <p>当前版本: v.1.0</p>
              <p>从苹果平台下载 <br/>Download from Apple store</p>
              <hr/>
              <MuiThemeProvider theme={theme}>
                <Button variant="contained" href="#outlined-buttons" disabled>暂无下载</Button>
              </MuiThemeProvider>
            </Col>
            <Col>
              <img class="android-style" src={android} style={{width:"20%"}}/>
              <hr/>
              <p>Android</p>
              <p>当前版本: v.1.0</p>
              <p>下载安卓包 <br/>Download Android package</p>
              <hr/>
              <button className="button-strap"><a href="http://gpe.tabtu.cn/gpe.apk">下载</a></button>
            </Col>
          </Row>
        </Container>
        </div>

        <div class="footer">
          Powered by Cloud Valley Tech Inc. © 2016, All Rights Reserved
        </div>

      </div>

    );
  }
}

export default App;
